import React, { Component } from 'react';
import { navigate } from 'gatsby';

class ProductRPage extends Component {
  componentDidMount() {
    navigate('/productos', { replace: true });
  }

  render() {
    return <div className="about-container" />;
  }
}

export default ProductRPage;
